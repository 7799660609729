<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <SearchList :loading="loading" :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
    </template>
    <!-- <template #toolbarRight>
      <a-button type="primary" @click="add">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template> -->
    <a-table :dataSource="dataSource" :columns="columns" :rowKey="(record,index)=>{return index}" @change="handleTableChange" :pagination="pagination" :loading="loading">
      <template #status="{ record }">
        <span :style="{color:record.status==='已修改' ? '#339933':'black'}">{{record.status}}</span>
      </template>
      <template #add="{ record }">
        <a @click="lookDetails(record )">
         查看详情
        </a>
      </template>
    </a-table>
  </suy-table>
  <!-- 修改详情 -->
  <a-drawer :width="400"  placement="right" v-model:visible="detailsVisible" :title="titleDetails"   footer="">
      <Details :infoMsg="infoMsg"  :subType="subType"/>
  </a-drawer>
  <!-- <a-modal v-model:visible="visible" title="新增修改" @ok="handleOk" :maskClosable="false" @cancel="cancel">
    <a-form :model="formState" layout="vertical">
      <a-form-item label="修改类型：" :wrapperCol="{flex:'auto'}">
        <a-select v-model:value="formState.type.value" allowClear placeholder='请选择修改类型'>
          <a-select-option v-for="item in formState.type" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="子分类：" :wrapperCol="{flex:'auto'}">
        <a-select v-model:value="formState.type.value" allowClear placeholder='请选择子分类'>
          <a-select-option v-for="item in formState.child" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="修改内容：" placeholder='请输入修改内容'>
        <a-textarea v-model:value="formState.remark" />
      </a-form-item>
    </a-form>
  </a-modal> -->
</template>
<script>
import SuyTable from '@/components/SuyTable'
import { reactive, toRefs, ref } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import { opsPage } from '@/api/trans/ops/edit'
import { onMounted } from 'vue'
import SearchList from '@/components/searchList'
import Details from './details'
export default {
  components: {
    SuyTable,
    PlusCircleOutlined,
    SearchList,
    Details
  },
  setup () {
    const state = reactive({
      infoMsg:{},
      subType:null,
      titleDetails:'',
      detailsVisible:false,
      formState: {},
      columns: [
        {
          title: '大类型',
          dataIndex: 'type.label',
          key: 'type.label'
        },
        {
          title: '子分类',
          dataIndex: 'subType.label',
          key: 'subType.label'
        },
        {
          title: '状态',
          dataIndex: 'status.label',
          key: 'status.label'
        },
        {
          title: '发起人',
          dataIndex: 'sponsor',
          key: 'sponsor'
        },
        {
          title: '发起时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '操作人',
          dataIndex: 'operation',
          key: 'operation'
        },
        {
          title: '修改时间',
          dataIndex: 'operationTime',
          key: 'operationTime'
        },
        {
          title: '处理备注',
          dataIndex: 'remark',
          key: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'add',
          slots: {
            customRender: 'add'
          },
          key: 'add'
        }
      ],
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      loading: false,
      visible: false
    })
    const searchMsg = ref({})
    const searchAdd = (e) => {
      state.pagination.current = 1
      searchMsg.value = e
      loadData()
    }
    const resetBtn = () => {
      state.pagination.current = 1
      searchMsg.value = {}
      loadData()
    }

    const lookDetails = (record) => {
      
      state.infoMsg = record
      console.log(state.infoMsg);
      state.subType = record.subType.value
      state.titleDetails = record.subType.label  + '-查看详情'
      state.detailsVisible = true
    }
    const handleTableChange = page => {
      state.pagination.current = page.current
      state.pagination.pageSize = page.pageSize
      loadData()
    }
    const formState = reactive({
      type: [],
      child: [],
      remark: ''
    })
    const add = () => {
      state.visible = true
    }
    const handleOk = () => {
      console.log('ok')
    }
    const searchBtn = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = () => {
      state.loading = true
      opsPage({
        ...searchMsg.value,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          // console.log(res.data);
          state.dataSource = res.data.records
          state.dataSource.forEach(record => {
            record.content = record.content ? JSON.parse(record.content) : {}
          })
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const cancel = () => {
      formState.remark = ''
      formState.type = []
      formState.child = []
    }
    onMounted(() => loadData())
    const iptData = ref([
      {
        type: "select",
        placeholder: "子分类",
        value:null,
        prop: "subType",
        width: 200,
        key:'value',
        keyVal:'label',
        opt: [
        { label: '修改取车人', value: 0 },
        { label: '关闭取车', value: 1 },
        { label: '关闭送车', value: 2 },
        { label: '打开取车', value: 3 },
        { label: '打开送车', value: 4 },
        { label: '修改送车地址', value: 15 },
        { label: '修改产品', value: 5 },
        { label: '修改目的地', value: 6 },
        { label: '修改车型', value: 7 },
        { label: '修改车架号', value: 8 },
        { label: '修改订单备注', value: 9 },
        { label: '修改费用', value: 19 },
        { label: '取消返款', value: 11 },
        { label: '打开开票', value: 12 },
        { label: '打开返款', value: 13 },
        { label: '到付改为预付X到付X', value: 16 },
        { label: '结算类型改记账', value: 17 },
        { label: '结算类型改月结', value: 18 },
        { label: '取消订单', value: 20 },
        { label: '取消开票', value: 21 },
        { label: '修改客户', value: 22 },
        { label: '结算类型改到收', value: 24 },
        { label: '修改分单员', value: 25 },
        { label: '修改录单员', value: 26 },
        { label: '修改预约时间', value: 27 }
      ],
      },
      {
        type: "select",
        placeholder: "状态",
        value: null,
        prop: "status",
        width: 200,
        opt: [
          {
            name:'申请',
            id:0
          },
          {
            name:'拒绝',
            id:2
          },
          {
            name:'处理完成',
            id:9
          },
        ]
      },
      {
        type: "input",
        placeholder: "发起人",
        value: null,
        prop: "sponsor",
        width: 200,
      },
    ])
    return {
      ...toRefs(state),
      iptData,
      formState,
      searchBtn,
      loadData,
      add,
      handleOk,
      cancel,
      handleTableChange,
      lookDetails,
      searchAdd,
      resetBtn
    }
  }
}
</script>

<style>
</style>
