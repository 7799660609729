<template>
  <div>
    <div class="box">
      <template v-if="subType === 0">
        <div class="marBotm">订单号：{{ infoMsg.content.orderId   }}</div>
        <div class="title">原取车人</div>
        <div>{{ infoMsg.oldContent }}</div>
      </template>
      <template v-else-if="subType === 12">
        <div class="marBotm">订单号：{{ infoMsg.content.orderId   }}</div>
        <div class="title">原开票信息</div>
        <div>无</div>
      </template>
      <template v-else-if="subType === 13">
        <div class="marBotm">订单号：{{ infoMsg.content.orderId   }}</div>
        <div class="title">原返款信息</div>
        <div>无</div>
      </template>
      <template v-else>
        <div class="marBotm">订单号：{{ infoMsg.content.orderId   }}</div>
        <div>{{ infoMsg.oldContent }}</div>
      </template>
    </div>
    <div class="box">
      <div class="title">更改结果</div>
      <template v-if="subType === 0">
        <div>姓名：{{ infoMsg.content.name }}</div>
        <div>证件号：{{ infoMsg.content.certificateNumber }}</div>
        <div>电话：{{ infoMsg.content.mobile }}</div>
      </template>
      <template v-if="subType === 1">
        <div>是否有费用变化：{{  infoMsg.content.isFee === 1 ? '是' : '否'}}</div>
        <div v-show="infoMsg.content.isFee === 1">运费（单台）：{{ infoMsg.content.transFee }}元</div>
        <div v-show="infoMsg.content.isFee === 1">取车费（单台）：{{ infoMsg.content.takeFee }}元</div>
      </template>
      <template v-if="subType === 2">
        <div>是否有费用变化：{{  infoMsg.content.isFee === 1 ? '是' : '否'}}</div>
        <div v-show="infoMsg.content.isFee === 1">运费（单台）：{{ infoMsg.content.transFee }}元</div>
        <div v-show="infoMsg.content.isFee === 1">送车费（单台）：{{ infoMsg.content.pickFee }}元</div>
      </template>
      <template v-if="subType === 3">
        <div>取车费用（单台）：{{ infoMsg.content.fee }}元</div>
        <div>取车方式：{{type[infoMsg.content.type]}}</div>
        <div>取车地址：{{ infoMsg.content.address }}</div>
      </template>
      <template v-if="subType === 4">
        <div>送车费用（单台）：{{ infoMsg.content.fee }}元</div>
        <div>送车方式：{{type[infoMsg.content.type]}}</div>
        <div>送车地址：{{ infoMsg.content.address }}</div>
      </template>
      <template v-if="subType === 15">
        <div>地址：{{ infoMsg.content.address }}</div>
      </template>
      <template v-if="subType === 5">
        <div>产品服务：{{ infoMsg.content.productName }}</div>
        <div>时效：{{ infoMsg.content.aging }}</div>
      </template>
      <template v-if="subType === 6">
        <div>目的地：{{ infoMsg.content.details }}</div>
      </template>
      <template v-if="subType === 7">
        <div>{{ infoMsg.content.brandTxte }}</div>
      </template>
      <template v-if="subType === 8">
        <div>{{ infoMsg.content.vinNoNew }}</div>
      </template>
      <template v-if="subType === 9">
        <div>备注：{{ infoMsg.content.remark }}</div>
      </template>
      <template v-if="subType === 19">
        <div>运费（单台）：{{ infoMsg.content.transFee }}元</div>
        <div>取车费（单台）：{{ infoMsg.content.pickFee }}元</div>
        <div>送车费（单台）：{{ infoMsg.content.takeFee }}元</div>
      </template>
      <template v-if="subType === 11">
        <div>无返款</div>
      </template>
      <template v-if="subType === 12">
        <div>开票主体：{{infoMsg.content.invoiceCarrier}}</div>
        <div>税率：{{infoMsg.content.taxRate}}%</div>
        <div>税目：{{infoMsg.content.taxItem}}</div>
      </template>
      <template v-if="subType === 13">
        <div>返款人：{{ infoMsg.content.refunder }}</div>
        <div>返款人电话：{{ infoMsg.content.refunderMobile }}</div>
        <div>返款人金额：{{ infoMsg.content.refundFee }}</div>
      </template>
      <template v-if="subType === 16">
        <div>结算方式：{{ infoMsg.content.orderSettlement?.label      }}</div>
        <div>预付金额：{{ infoMsg.content.advanceCharge }}</div>
      </template>
      <template v-if="subType === 17 || subType === 18 || subType === 24">
        <div>结算方式：{{ infoMsg.content.orderSettlement?.label    }}</div>
      </template>
      <template v-if="subType === 20">
        <div>取消订单</div>
      </template>
      <template v-if="subType === 21">
        <div>无</div>
      </template>
      <template v-if="subType === 22">
        <div>名称：{{ infoMsg.content.customerName }}</div>
        <div>电话：{{ infoMsg.content.customerMobile }}</div>
      </template>
      <template v-if="subType === 25">
        <div>分单员：{{ infoMsg.content.userName}}</div>
      </template>
      <template v-if="subType === 26">
        <div>录单员：{{ infoMsg.content.userName}}</div>
      </template>
      <template v-if="subType === 27">
        <div v-if="infoMsg.content?.vinNos">
          <div v-for="item in infoMsg.content.vinNos" :key="item.id">
           {{ item }} : {{ infoMsg.content.time }}
          </div>
        </div>
      </template>

      <div class="m-t3">原因：{{ infoMsg.reason }}</div>
    </div>
    <div class="box1">
      <div class="title">审核</div>
      <div>审核结果：{{ infoMsg.status.label }}</div>
      <div>审核原因：{{ infoMsg.remark }}</div>
      <div>审核时间：{{ infoMsg.operationTime }}</div>
      <div>审核人：{{ infoMsg.operation }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  subType: {
    type: Number,
    default: null
  },
  infoMsg: {
    type: Object,
    default: {}
  }
})
const type = ref({
  3: '地跑',
  2: '小板'
})

</script>

<style lang="less" scoped>
.box {
  padding: 20px 10px;
  border-bottom: 1px solid #ccc;
  color: #344563;

  div {
    margin-bottom: 5px;
    white-space: pre-line;
  }
  .marBotm {
    margin-bottom: 20px;
  }
}

.box1 {
  padding: 20px 10px;
  color: #344563;
  div {
    margin-bottom: 7px;
  }
}
.title {
  font-weight: 700;
  color: #344563;
}
</style>